import styled from 'styled-components';
import { blackPrimary, inverse } from '@salutejs/plasma-tokens';
import React from 'react';

const StyledTooltip = styled.div`
    position: absolute;
    z-index: 2;
    margin: 0;
    display: block;
    border-radius: 10px;
    background: ${blackPrimary};
    color: ${inverse};
    padding: 12px 14px;
    width: 303px;
    bottom: 4px;
    left: 24px;
    transform: translateY(100%);

    &::before {
        content: '';
        position: absolute;
        top: 1px;
        left: 12px;
        height: 8px;
        background: ${blackPrimary};
        width: 21px;
        clip-path: path('M0 8L20 7.99999C11.1111 8.00005 10 0.571428 10 0C10 0.571428 8.67268 8.00599 0 8Z');
        transform: translateY(-100%);
    }

    & div {
        margin: 0;
        display: block;
    }
`;

export const Tooltip: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ children, className }) => (
    <StyledTooltip
        className={className}
        onClick={(e) => {
            e.stopPropagation();
            e.preventDefault;
        }}
    >
        {children}
    </StyledTooltip>
);
