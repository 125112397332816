import React from 'react';
import { Container } from '@salutejs/plasma-web';
import styled from 'styled-components';

import { Header } from '../Header/Header';
import { Navbar } from '../Navbar/Navbar';

import { PropsType } from '@/types/PropsType';

const PageContainer = styled.div`
    position: relative;
    max-height: 100vh;
    overflow-y: auto;
`;

const StyledContainer = styled(Container)`
    @media (min-width: 1200px) {
        --plasma-grid-margin: 4.5rem;
        max-width: none;
    }
`;

const Dummy = styled.div`
    height: 64px;
`;

type LayoutProps = PropsType<typeof Header> & {
    isHeaderVisible?: boolean;
};

export const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
    children,
    isHeaderVisible = true,
    ...props
}) => {
    return (
        <PageContainer>
            {isHeaderVisible ? <Header {...props} /> : null}
            <StyledContainer>
                {props.isAuthenticated ? <Navbar /> : null}
                {children}
            </StyledContainer>
            <Dummy />
        </PageContainer>
    );
};
