import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { usePathname } from 'next/navigation';
import { BodyS, Headline2 } from '@salutejs/plasma-web';
import { text, inverse, white, accent } from '@salutejs/plasma-tokens';

import { CLIENT_ROUTES } from '@/utils/routes';

const StyledContainer = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    gap: 16px;
    flex-wrap: wrap;
`;

const StyledTabs = styled.div`
    display: flex;
    flex-wrap: wrap;
    border-radius: 24px;
    padding: 2px;
    background: ${white};
`;

const StyledTab = styled(Link)<{ isActive: boolean }>`
    border-radius: 24px;
    color: ${({ isActive }) => (isActive ? inverse : text)};
    text-decoration: none;
    padding: 8px 24px;
    background: ${({ isActive }) => (isActive ? accent : 'transparent')};
`;

export const Navbar: React.FC = () => {
    const pathname = usePathname();

    return (
        <StyledContainer>
            <Headline2>Single Student University</Headline2>
            <StyledTabs>
                {Object.values(CLIENT_ROUTES).map((route) => {
                    const isActive = pathname === route.href;

                    return route.title ? (
                        <StyledTab isActive={isActive} key={route.href} href={route.href}>
                            <BodyS bold={isActive}>{route.title}</BodyS>
                        </StyledTab>
                    ) : null;
                })}
            </StyledTabs>
        </StyledContainer>
    );
};
