import React from 'react';
import { useController, useForm } from 'react-hook-form';
import { IconCloudUpload, IconEye } from '@salutejs/plasma-icons';

import { FieldWithMultiselect } from '../types';

import {
    FieldWrapper,
    StyledChevron,
    StyledError,
    StyledTextArea,
    StyledLabel,
    StyledSelect,
    StyledInput,
    StyledTogglePasswordButton,
    StyledDragArea,
    AccentSpan,
    StyledMultiSelect,
} from './styled';

type FieldComponentProps = {
    form: ReturnType<typeof useForm>;
    field: FieldWithMultiselect;
};

export const FieldComponent: React.FC<FieldComponentProps> = ({ form, field }) => {
    const [passwordType, setPasswordType] = React.useState('password');
    const [fileString, setFileString] = React.useState<string | undefined>(undefined);

    const required = field.required === undefined ? true : field.required;

    const fieldProps = form.register(field.name, { ...field, required });

    const controller = useController({
        control: form.control,
        rules: { required },
        ...field,
    });

    const toggle = () => {
        setPasswordType((prev) => (prev === 'text' ? 'password' : 'text'));
    };

    const errorText = field.errorText || 'Обязательное поле';

    switch (field.element) {
        case 'input':
            return (
                <StyledLabel>
                    {field.label}
                    <FieldWrapper>
                        {field.type === 'file' ? (
                            <StyledDragArea>
                                <IconCloudUpload size="s" />
                                {fileString || (
                                    <span>
                                        Перетащите или <AccentSpan>выберите файл</AccentSpan>
                                    </span>
                                )}
                                <StyledInput
                                    {...fieldProps}
                                    accept={field.accept}
                                    type={field.type}
                                    placeholder={field.placeholder}
                                    onChange={(e) => {
                                        fieldProps.onChange(e);
                                        setFileString(e.target.files?.item(0)?.name);
                                    }}
                                />
                            </StyledDragArea>
                        ) : (
                            <StyledInput
                                {...fieldProps}
                                autoComplete={field.autoComplete}
                                type={field.type === 'password' ? passwordType : field.type}
                                placeholder={field.placeholder}
                            />
                        )}
                        {field.type === 'password' && (
                            <StyledTogglePasswordButton onClick={toggle} isVisible={passwordType === 'text'}>
                                <IconEye />
                            </StyledTogglePasswordButton>
                        )}
                    </FieldWrapper>
                    {form.formState.errors[field.name] ? <StyledError>{errorText}</StyledError> : null}
                </StyledLabel>
            );
        case 'textarea':
            return (
                <StyledLabel>
                    {field.label}
                    <StyledTextArea {...fieldProps} placeholder={field.placeholder} />
                    {form.formState.errors[field.name] ? <StyledError>{errorText}</StyledError> : null}
                </StyledLabel>
            );
        case 'select':
            return (
                <StyledLabel>
                    {field.label}
                    <FieldWrapper>
                        <StyledSelect {...controller.field}>
                            <option value="" disabled>
                                {field.placeholder || 'Выберите'}
                            </option>
                            {field.getOptions(form.getValues()[field.parentName || '']).map((option, i) => (
                                <option key={i} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </StyledSelect>
                        <StyledChevron size="s" />
                    </FieldWrapper>
                    {form.formState.errors[field.name] ? <StyledError>{errorText}</StyledError> : null}
                </StyledLabel>
            );
        case 'multiselect': {
            return <StyledMultiSelect {...field} {...controller.field} items={field.getOptions()} listHeight="" />;
        }
        default:
            return null;
    }
};
