import React from 'react';
import styled from 'styled-components';
import { Button } from '@salutejs/plasma-web';
import { useForm } from 'react-hook-form';

import { FieldComponent } from './components/FieldComponent';
import { QuestionFields } from './components/QuestionFields';
import { Field } from './types';

import { LSKey, getLSValue, setLSValue } from '@/utils/ls';
import { defaultQuestionValues } from '@/constants/questions';

const StyledForm = styled.form`
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    padding: 0 36px 32px 40px;

    gap: 16px;
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
`;

const ButtonsContainer = styled.div`
    margin-top: 16px;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
`;

type BaseFormProps<T extends string> = {
    fields: Field<T>[];
    submitText?: string;
    withCancelButton?: boolean;
    isLoading?: boolean;
    lsKey?: LSKey;
    defaultQuestionsData?: Partial<typeof defaultQuestionValues>;
    handleDeleteClick?(): void;
};

type FormProps<T extends string> = BaseFormProps<T> &
    (
        | {
              handleSubmit: (data: Record<T, string> & typeof defaultQuestionValues) => void;
              withQuestion: true;
          }
        | {
              handleSubmit: (data: Record<T, string>) => void;
              withQuestion?: false;
          }
    );

export const Form = <T extends string>({
    fields,
    withQuestion,
    submitText = 'Сохранить',
    withCancelButton = true,
    isLoading,
    lsKey,
    defaultQuestionsData = defaultQuestionValues,
    handleSubmit,
    handleDeleteClick,
    children,
}: React.PropsWithChildren<FormProps<T>>) => {
    const fieldsDefaultValues = React.useMemo(
        () =>
            fields.reduce(
                (acc, field) => ({ ...acc, [field.name]: field.defaultValue ?? '' }),
                {} as Record<T, string>,
            ),
        [fields],
    );

    const propsDefaultValues = React.useMemo(
        () => ({
            ...fieldsDefaultValues,
            ...(withQuestion ? defaultQuestionsData : {}),
        }),
        [withQuestion, defaultQuestionsData, fieldsDefaultValues],
    );

    const lsDefaultValues = getLSValue(lsKey);

    const defaultValues = lsDefaultValues ?? propsDefaultValues;

    const form = useForm({ defaultValues });

    React.useEffect(() => {
        return () => {
            setLSValue(lsKey, form.getValues());
        };
    }, [form, lsKey]);

    const domain = form.watch('domain_id');
    const subDomain = form.watch('subdomain_id');

    const domainRef = React.useRef(domain);
    const subDomainRef = React.useRef(subDomain);

    React.useEffect(() => {
        if (domainRef.current !== domain) {
            form.resetField('disciplines_id', { defaultValue: '' });
            form.resetField('subdomain_id', { defaultValue: '' });
        }
    }, [domain, form]);

    React.useEffect(() => {
        if (subDomainRef.current !== subDomain) {
            form.resetField('disciplines_id', { defaultValue: '' });
        }
    }, [subDomain, form]);

    const reset = React.useCallback(() => {
        form.reset(propsDefaultValues);

        // костыль для дефолтных значений в селектах при reset'е
        setTimeout(() => {
            form.trigger('domain_id');
        });

        setTimeout(() => {
            form.clearErrors();
        }, 1);
    }, [form, propsDefaultValues]);

    return (
        <StyledForm onSubmit={form.handleSubmit(handleSubmit)} onReset={reset}>
            {fields.map((field) => (
                <FieldComponent key={field.name} field={field} form={form} />
            ))}

            {withQuestion ? <QuestionFields form={form} /> : null}

            {children}

            <ButtonsContainer>
                {handleDeleteClick ? (
                    <Button view="critical" onClick={handleDeleteClick} isLoading={isLoading}>
                        Удалить
                    </Button>
                ) : null}
                {withCancelButton ? (
                    <Button view="secondary" type="reset">
                        Отменить
                    </Button>
                ) : null}
                <Button
                    view="primary"
                    type="submit"
                    stretch={!withCancelButton && !handleDeleteClick}
                    isLoading={isLoading}
                >
                    {submitText}
                </Button>
            </ButtonsContainer>
        </StyledForm>
    );
};
