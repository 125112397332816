import React from 'react';
import styled from 'styled-components';
import { IconInfo } from '@salutejs/plasma-icons';

import { Tooltip } from '@/components/Tooltip/Tooltip';

const StyledWrapper = styled.div`
    position: absolute;
    top: -2px;
    left: 64px;
`;

const StyledTooltip = styled(Tooltip)`
    left: -10px !important;
    bottom: -5px !important;

    & ul {
        display: block;
        margin: 8px 0 0;
        padding: 0 0 0 28px;
    }
`;

export const InfoIcon: React.FC = () => {
    const [tooltipVisible, setTooltipVisible] = React.useState(false);

    return (
        <StyledWrapper
            onMouseLeave={() => {
                setTooltipVisible(false);
            }}
            onMouseOver={() => {
                setTooltipVisible(true);
            }}
        >
            <IconInfo />
            {tooltipVisible ? (
                <StyledTooltip>
                    Вопрос не требующий дополнительной валидации со стороны экспертов или разметчиков. Процесс:
                    <ul>
                        <li>Получение ответов через модель.</li>
                        <li>Сравнение ответов модели с верным ответом.</li>
                        <li>Подсчет доли верных ответов.</li>
                    </ul>
                </StyledTooltip>
            ) : null}
        </StyledWrapper>
    );
};
