import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { useMutation } from 'react-query';
import { BodyL, BodyS, Button } from '@salutejs/plasma-web';
import { surfaceSolid01, secondary } from '@salutejs/plasma-tokens';
import { IconLogout } from '@salutejs/plasma-icons';

import { sendRequest } from '@/api/sendRequest';
import { CLIENT_ROUTES, SERVER_ROUTES } from '@/utils/routes';

const StyledHeader = styled.header`
    position: sticky;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;

    top: 0;
    margin: 4px 0 28px;
    padding: 4px 20px;
    background-color: ${surfaceSolid01};
    /* stylelint-disable */
    box-shadow:
        0px 4px 14px 0px rgba(0, 0, 0, 0.08),
        0px 1px 1px 0px rgba(0, 0, 0, 0.05);
    /* stylelint-enable */
`;

const HeaderSide = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const StyledImage = styled(Image)`
    border-radius: 50%;
`;

const StyledButton = styled(Button)`
    padding: 0;
    color: ${secondary} !important;
    background-color: transparent !important;

    & span div:first-child {
        margin-right: 4px;
    }
`;

type HeaderTitleProps = {
    iconSize?: number;
    short?: boolean;
};

export const HeaderTitle: React.FC<HeaderTitleProps> = ({ iconSize = 48, short = true }) => {
    return (
        <>
            <StyledImage width={iconSize} height={iconSize} src="/favicon.ico" alt="ssu" />
            <BodyL bold>{short ? 'SSU' : 'Single Student University'}</BodyL>
        </>
    );
};

type HeaderProps = {
    isAuthenticated: boolean;
};

export const Header: React.FC<HeaderProps> = ({ isAuthenticated }) => {
    const router = useRouter();

    const { mutate, isLoading } = useMutation({
        mutationFn: () => sendRequest({ path: SERVER_ROUTES.logout.href, params: undefined }),
        onSuccess: () => router.push(CLIENT_ROUTES.login.href),
    });

    return (
        <StyledHeader>
            <HeaderSide>
                <HeaderTitle />
            </HeaderSide>
            <HeaderSide>
                {isAuthenticated ? (
                    <StyledButton view="secondary" onClick={mutate as () => void} isLoading={isLoading}>
                        <BodyS bold>Выйти</BodyS>
                        <IconLogout color={secondary} />
                    </StyledButton>
                ) : null}
            </HeaderSide>
        </StyledHeader>
    );
};
