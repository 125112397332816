export const LS_KEYS = {
    question: 'add-question-form',
    content: 'add-content-form',
    discipline: 'add-discipline-form',
} as const;

export type LSKey = (typeof LS_KEYS)[keyof typeof LS_KEYS];

export const getLSValue = (key?: LSKey) => {
    const lsItem = key ? localStorage.getItem(key) : undefined;
    const value = lsItem ? JSON.parse(lsItem) : undefined;

    return value;
};

export const setLSValue = (key?: LSKey, value?: Record<string, unknown>) => {
    if (key) {
        typeof value === 'object' ? localStorage.setItem(key, JSON.stringify(value)) : localStorage.removeItem(key);
    }
};
