import React from 'react';
import styled from 'styled-components';
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form';
import { RadioGroup, Button, Checkbox, Radiobox } from '@salutejs/plasma-web';
import { warning, accent } from '@salutejs/plasma-tokens';
import { IconPlus, IconTrash } from '@salutejs/plasma-icons';

import { StyledError, StyledTextArea, StyledLabel } from './styled';
import { InfoIcon } from './InfoIcon';

import { QUESTION_TO_ANSWER_LABEL_MAP, QUESTION_TYPES, defaultQuestionValues } from '@/constants/questions';

const StyledRadioGroup = styled(RadioGroup)`
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    & label > div:last-child {
        margin-left: 8px;
    }
`;

const AnswersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
`;

const StyledAddAnswerButton = styled(Button)`
    color: ${accent} !important;

    & svg {
        color: ${accent} !important;
    }
`;

const InputWrapper = styled.div`
    position: relative;
`;

const InputButtonsWrapper = styled.div`
    position: absolute;
    display: flex;
    right: 8px;
    top: 12px;
`;

const TrashIconWrapper = styled.div`
    cursor: pointer;
`;

type QuestionFieldsProps = {
    form: UseFormReturn<typeof defaultQuestionValues>;
};

export const QuestionFields: React.FC<QuestionFieldsProps> = ({ form }) => {
    const answers = useFieldArray({
        name: 'answers',
        control: form.control,
        rules: {
            minLength: 1,
            required: true,
            validate: (values, formData) => {
                if (formData.question_type === 'closed' && !values.some(({ isChecked }) => isChecked)) {
                    return 'Хотя бы один ответ должен быть отмечен как правильный';
                }

                return true;
            },
        },
    });

    const watchRadio = form.watch('question_type');

    return (
        <>
            <StyledLabel>
                Тип вопроса
                <Controller
                    name="question_type"
                    control={form.control}
                    render={({ field }) => (
                        <StyledRadioGroup {...field}>
                            {QUESTION_TYPES.map((question) => (
                                <Radiobox
                                    key={question.value}
                                    name="radio-1"
                                    value={question.value}
                                    label={question.label}
                                    defaultChecked={question.value === field.value}
                                />
                            ))}
                        </StyledRadioGroup>
                    )}
                />
            </StyledLabel>

            <StyledLabel>
                Вопрос
                <StyledTextArea
                    {...form.register('question_text', { required: true })}
                    placeholder="Введите ваш вопрос"
                />
                {watchRadio === 'closed' && <InfoIcon />}
                {form.formState.errors.question_text ? <StyledError color={warning}>Введите вопрос</StyledError> : null}
            </StyledLabel>

            <StyledLabel>
                {QUESTION_TO_ANSWER_LABEL_MAP[watchRadio]}
                <AnswersWrapper>
                    {answers.fields.map((field, index) => (
                        <InputWrapper key={field.id}>
                            <StyledTextArea
                                {...form.register(`answers.${index}.value`, {
                                    required: watchRadio !== 'generative',
                                    deps: ['radio'],
                                })}
                                placeholder="Введите ваш ответ"
                            />
                            {form.formState.errors.answers?.[index] ? (
                                <StyledError color={warning}>Введите ответ</StyledError>
                            ) : null}

                            <InputButtonsWrapper>
                                {watchRadio === 'closed' && (
                                    <Checkbox {...form.register(`answers.${index}.isChecked`)} />
                                )}

                                <TrashIconWrapper
                                    onClick={() => {
                                        answers.remove(index);
                                    }}
                                >
                                    <IconTrash />
                                </TrashIconWrapper>
                            </InputButtonsWrapper>
                        </InputWrapper>
                    ))}
                </AnswersWrapper>
                {form.formState.errors.answers?.root ? (
                    <StyledError color={warning}>{form.formState.errors.answers.root.message}</StyledError>
                ) : null}

                <StyledAddAnswerButton
                    text="Добавить ответ"
                    contentLeft={<IconPlus />}
                    onClick={() => {
                        answers.append({
                            value: '',
                            isChecked: false,
                        });
                    }}
                />
            </StyledLabel>
        </>
    );
};
