export const CLIENT_ROUTES = {
    root: { href: '/', title: 'Список контента' },
    login: { href: '/login', title: '' },
    questions: { href: '/questions', title: 'Вопросы' },
    // sft: { href: '/sft', title: 'SFT' },
    disciplines: { href: '/disciplines', title: 'Справочник дисциплин' },
    statistics: { href: '/statistics', title: 'Отчетность' },
} as const;

type ClientRoutesT = typeof CLIENT_ROUTES;

export type Pathname = ClientRoutesT[keyof ClientRoutesT]['href'];

export const SERVER_ROUTES = {
    login: { href: '/api/login' },
    logout: { href: '/api/logout' },
    gigaAuth: { href: '/api/giga-auth' },
    gigaCompletions: { href: '/api/giga-completions' },
} as const;
